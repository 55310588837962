import { template as template_9968e51eae374521b0d79328df46942f } from "@ember/template-compiler";
const SidebarSectionMessage = template_9968e51eae374521b0d79328df46942f(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
